import React from "react";
import classes from "./WsparcieSection2.module.css";
import { Link } from "react-router-dom";
import image from "./assets/images/6.jpg";
function WsparcieSection2() {
  return (
    <section className={classes.section__wrapper}>
      <div className={classes.column__left}>
        <img src={image} alt="Zdjęcie probówek w statywie" />
      </div>
      <div className={classes.column__right}>
        <p className={classes.right__firstparagraph}>PROGRAM LOJALNOŚCIOWY</p>
        <h1>Program Dla Doktorantów</h1>
        <hr />
        <p className={classes.right_lastparagraph}>
          Jest to Program skierowany do grup badawczych, skupiających w swoim
          gronie Naukowców w tym: Profesorów, Doktorów, Doktorantów, Asystentów
          współpracujących z Firmą Symbios.
        </p>
        <Link to="/PDD">Dowiedz się więcej!</Link>
      </div>
    </section>
  );
}

export default WsparcieSection2;
