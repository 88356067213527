import React from "react";
import classes from "./TrustSection.module.css";
import OFirmieThree from "../assets/images/4.jpg";
function TrustSection() {
  return (
    <section className={classes.section__background}>
      <div className={classes.main__wrapper}>
        <div className={classes.section__left}>
          <img src={OFirmieThree} alt="Preparat mikroskopowy" />
        </div>
        <div className={classes.section__right}>
          <h1>ZAUFANIE</h1>
          <hr />
          <p>
            Zdobyliśmy zaufanie naszych partnerów i klientów. Są nimi przedstawiciele świata nauki we wszystkich wiodących placówkach naukowo-badawczych i
            diagnostycznych w Polsce. Pracownicy naszej firmy regularnie uczestniczą we wszelkich międzynarodowych szkoleniach merytorycznych, by podążając za
            nowościami na rynku biotechnologii, w jeszcze lepszy sposób, dzieląc się zdobytą wiedzą, cały czas poprawiać jakość świadczonych dla Państwa usług.
          </p>
        </div>
      </div>
      <div className={classes.bottom__divider}></div>
    </section>
  );
}

export default TrustSection;
