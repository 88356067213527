import React from "react";
import classes from "./LifeSection.module.css";
import OFirmieFirst from "../assets/images/2.jpg";
function LifeSection() {
  return (
    <section className={classes.section__background}>
      <div className={classes.main__wrapper}>
        <div className={classes.section__left}>
          <img src={OFirmieFirst} alt="Preparat mikroskopowy" />
        </div>
        <div className={classes.section__right}>
          <h1>ŻYCIE</h1>
          <hr />
          <p>
            Firma powstała w 1995 roku. Oferujemy produkty dla biologii molekularnej XXI wieku w obszarach badawczych takich jak genomika, proteomika, biologia
            komórki i wielu innych. Naszym specjalistom przyświeca maksyma, że największą nagrodą jaką życie oferuje, jest możliwość wytężonej pracy nad czymś,
            co jest tego warte.
          </p>
        </div>
      </div>
    </section>
  );
}

export default LifeSection;
