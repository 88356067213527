import React from "react";
import classes from "./SearchNoRes.module.css";

function SearchNoRes() {
  return (
    <div data-type="search__res" className={classes.nores}>
      Brak wyników dla wskazanych kryteriów
    </div>
  );
}

export default SearchNoRes;
