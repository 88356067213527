import React from "react";
import classes from "./SingleCompanyLogo.module.css";
function SingleCompanyLogo(props) {
  return (
    <div className={classes.image__container}>
      <a href={props.www}>
        <img
          src={props.image}
          className={classes.single__image}
          alt={props.name}
        />
      </a>
    </div>
  );
}

export default SingleCompanyLogo;
