import React from "react";
import classes from "./Oferta.module.css";
import OfferSectionBoxes from "../components/offerSectionBoxes/OfferSectionBoxes";
import Instructions from "../components/Instructions";
function Oferta() {
  return (
    <div className={classes.main__wrapper}>
      <Instructions />
      <OfferSectionBoxes />
    </div>
  );
}

export default Oferta;
