import React from "react";
import LifeSection from "../components/LifeSection/LifeSection";
import KindnessSection from "../components/KindnessSection/KindnessSection";
import classes from "./OFirmie.module.css";
import TrustSection from "../components/trustSection/TrustSection";
import AsortimentSnippetSection from "../components/asortimentSnippetSection/AsortimentSnippetSection";
function OFirmie() {
  return (
    <div className={classes.main__wrapper}>
      <LifeSection />
      <KindnessSection />
      <TrustSection />
      <AsortimentSnippetSection />
    </div>
  );
}

export default OFirmie;
