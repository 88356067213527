import React from "react";
import classes from "./InputField.module.css";
function InputField(props) {
  return (
    <div className={classes.inputField__Column}>
      <label htmlFor={props.name}>
        <span className={classes.input__label}>
          {props.text}
          <span className="required">*</span>
        </span>
      </label>
      <input
        type={props.type}
        name={props.name}
        id={props.name}
        placeholder={props.placeholder}
        required
        onChange={(e) => props.inputChangeHandler(e.target.value, props.name)}
      />
    </div>
  );
}

export default InputField;
