import React from "react";
import classes from "./Instructions.module.css";
import "./Instructions.css";
import { useNavigate, useLocation } from "react-router-dom";

import { Chrono } from "react-chrono";
import firstStepImage from "./assets/images/krok1.webp";
import secondStepImage from "./assets/images/krok 2.webp";
import thirdStepImage from "./assets/images/krok3.webp";
import fourthStepImage from "./assets/images/krok4.jpg";
import fifthStepImage from "./assets/images/krok5.jpg";
function Instructions() {
  const currentlocation = useLocation()?.pathname;
  console.log(currentlocation);
  const items = [
    {
      cardTitle: "Krok 1",
      cardSubtitle: "Wybierz kategorię szukanego produktu",
      media: {
        name: "Krok 1",
        source: {
          url: firstStepImage,
        },
        type: "IMAGE",
      },
    },
    {
      cardTitle: "Krok 2",
      cardSubtitle: "Wybierz odpowiedniego producenta i znajdź na jego stronie internetowej interesujący Cię numer katalogowy lub numer CAS",
      media: {
        name: "Krok 2",
        source: {
          url: secondStepImage,
        },
        type: "IMAGE",
      },
    },
    {
      cardTitle: "Krok 3",
      cardSubtitle: "Numer katalogowy ze strony producenta wpisz we wskazane okno",
      media: {
        name: "Krok 3",
        source: {
          url: thirdStepImage,
        },
        type: "IMAGE",
      },
    },
    {
      cardTitle: "Krok 4",
      cardSubtitle: "Dodaj do oferty tyle produktów i w takiej ilości ile potrzebujesz",
      media: {
        name: "Krok 4",
        source: {
          url: fourthStepImage,
        },
        type: "IMAGE",
      },
    },
    {
      cardTitle: "Krok 5",
      cardSubtitle:
        "W koszyku ofertowym przejdź do podsumowania i wypełnij formularz ofertowy. Odpowiedź przyjdzie do Ciebie tak szybko jak tylko będzie to możliwe!",
      media: {
        name: "Krok 5",
        source: {
          url: fifthStepImage,
        },
        type: "IMAGE",
      },
    },
  ];

  const navigate = useNavigate();
  const clickHandler = () => {
    navigate(-1, { replace: true });
  };

  return (
    <div className={classes.instructions}>
      <div className={classes.first__decoration}>
        <div className={classes.inside__divider}></div>
        <p>Zapytanie ofertowe – instrukcja</p>
        <h1>JAK ZŁOŻYĆ ZAPYTANIE O OFERTĘ</h1>
      </div>
      <div style={{ width: "90vw", margin: "0 auto" }} className={classes.chrono}>
        <Chrono
          items={items}
          mode="VERTICAL_ALTERNATING"
          scrollable={{ scrollbar: true }}
          hideControls
          theme={{
            primary: "#9f2436",
            secondary: "#333333",
            cardBgColor: "#f1f4f6",
            cardTitleColor: "#1c3975",
            titleColorActive: "red",
          }}
          fontSizes={{ cardSubtitle: "1.4rem", cardText: "0.8rem", cardTitle: "3.2rem", title: "1rem" }}
          classNames={{
            cardMedia: classes.fit__contain,
            cardTitle: classes.flex__title,
            cardSubtitle: classes.card__subtitle,
          }}
        />
      </div>
      {currentlocation === "/Instrukcja" && (
        <div className={classes.button__wrapper}>
          <button className={classes.manufacturers__button} onClick={clickHandler}>
            Powrót do poprzedniej strony
          </button>
        </div>
      )}
    </div>
  );
}

export default Instructions;
