import React from "react";
import classes from "./WsparcieSection1.module.css";
import portfolio from "../download/Symbios_Ulotka_01.pdf";
import image from "./assets/images/5.jpg";
function WsparcieSection1() {
  return (
    <section className={classes.section__wrapper}>
      <div className={classes.column__left}>
        <p>
          Pełne portfolio naszych producentów znajdziesz wciskając przycisk
          poniżej
        </p>
        <a href={portfolio} target="_blank" rel="noreferrer">
          Pobierz
        </a>
      </div>
      <div className={classes.column__right}>
        <img src={image} alt="Zdjęcie probówek w statywie" />
      </div>
    </section>
  );
}

export default WsparcieSection1;
