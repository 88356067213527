import React from "react";
import classes from "./DoubleItem.module.css";

function DoubleItem(props) {
  return (
    <div className={classes.singleItem__box}>
      <div className={classes.column__left}>
        <h2>{props.company}</h2>
        <p dangerouslySetInnerHTML={{ __html: props.text }}></p>
      </div>
      <div className={classes.column__right}>
        <a href={props.href} target="_blank" rel="noreferrer">
          <img src={props.logo} alt={props.alt} />
        </a>
      </div>
    </div>
  );
}

export default DoubleItem;
