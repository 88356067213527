import { useEffect, useState } from "react";
import React from "react";
import classes from "./CheckoutSummary.module.css";

function CheckoutSummary() {
  const [cartContent, setCartContent] = useState(
    JSON.parse(localStorage.getItem("cart"))
  );
  useEffect(() => {
    const timer = setTimeout(() => {
      setCartContent(JSON.parse(localStorage.getItem("cart")));
    }, 1000);
    return () => clearTimeout(timer);
  }, [cartContent]);

  return (
    <div>
      <h2 className={classes["checkout__bigtext--h2"]}>
        W Twojej ofercie znajdują się następujące produkty:
      </h2>
      <table className={classes.table__main}>
        <thead>
          <tr>
            <th>Nazwa produktu</th>
            <th>Numer katalogowy</th>
            <th>Ilość</th>
          </tr>
        </thead>
        <tbody>
          {cartContent.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.title}</td>
                <td>{item.sku}</td>
                <td>{item.amount}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default CheckoutSummary;
