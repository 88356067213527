import React from "react";
import { Link } from "react-router-dom";
import classes from "./AsortimentSnippetSection.module.css";
import SingleItem from "./SingleItem";
import abcamLogo from "../assets/images/abcam.webp";
import starlabLogo from "../assets/images/starlab.webp";
import amLogo from "../assets/images/activemotif.webp";

function AsortimentSnippetSection() {
  const companies = [
    {
      id: 1,
      href: "https://www.abcam.com/",
      company: "Abcam",
      text: "Światowej sławy producent m.in. przeciwciał oraz zestawów o bardzo szerokim zastosowaniu, posiadający w ofercie ponad 110 000 produktów. Tak szeroki asortyment gwarantuje, że każdy znajdzie w ofercie produkt odpowiedni do swojego zapotrzebowania",
      logo: abcamLogo,
      alt: "Logo Abcam",
    },
    {
      id: 2,
      href: "https://www.starlabgroup.com/",
      company: "Starlab",
      text: "Producent wysokich jakościowo, sterylizowanych promieniowaniem gamma materiałów zużywalnych, zapewniających precyzję oraz powtarzalność wykonania",
      logo: starlabLogo,
      alt: "Logo Starlab",
    },
    {
      id: 3,
      href: "https://www.activemotif.com/",
      company: "Active Motif",
      text: "Firma nastawiona na produkcję odczynników do szeroko pojętych badań epigenetycznych, od sławnych już zestawów takich jak CUT&TAG oraz ATAC-SEQ po przeciwciała przeciwko konkretnym modyfikacjom histonowym",
      logo: amLogo,
      alt: "Logo Active Motif",
    },
  ];

  const listItems = companies.map((company) => (
    <SingleItem
      key={company.id}
      href={company.href}
      company={company.company}
      text={company.text}
      logo={company.logo}
      alt={company.alt}
    />
  ));
  return (
    <section className={classes.main__wrapper}>
      <div className={classes.main__box}>
        <h1>
          Dysponujemy pełnym asortymentem wielu światowej klasy producentów
        </h1>
        <hr />
        {listItems}
        <div className={classes.button__wrapper}>
          <Link to="/oferta" className={classes.manufacturers__button}>
            WSZYSCY NASI PRODUCENCI
          </Link>
        </div>
      </div>
    </section>
  );
}

export default AsortimentSnippetSection;
