import React from "react";
import classes from "./KindnessSection.module.css";
import OFirmieTwo from "../assets/images/3.jpg";
function KindnessSection() {
  return (
    <section className={classes.section__background}>
      <div className={classes.main__wrapper}>
        <div className={classes.section__left}>
          <h1>ŻYCZLIWOŚĆ</h1>
          <hr />
          <p>
            Nasz zespół tworzą wysoko wykwalifikowani absolwenci szkół wyższych. Praca zespołu młodych i dynamicznych ludzi, ich wiedza, talent, doświadczenie i
            kreatywność stają się elementami wsparcia sukcesu naszych klientów.
          </p>
        </div>
        <div className={classes.section__right}>
          <img src={OFirmieTwo} alt="Preparat mikroskopowy" />
        </div>
      </div>
    </section>
  );
}

export default KindnessSection;
