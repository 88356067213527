import React from "react";
import classes from "./Partnerzy.module.css";
import PartnersBox from "../components/PartnersBox";
function Partnerzy() {
  return (
    <div className={classes.main__wrapper}>
      <PartnersBox />
    </div>
  );
}

export default Partnerzy;
