import React, { useRef } from "react";
import { Link } from "react-router-dom";
import classes from "./Cart.module.css";
import CartItem from "./CartItem";
import { AiOutlineClose, AiOutlineProfile } from "react-icons/ai";

function Cart(props) {
  const sideBarEl = useRef();

  const contentClassname = props.cartOpen ? classes.cart__sidebar : `${classes.cart__sidebar} ${classes["sidebar-invisible"]}`;

  return (
    <>
      {props.cartOpen && <div data-type="search__res" className={classes.backdrop} onClick={props.cartCloseHandler}></div>}
      <div className={classes.cart__miniature} onClick={props.cartCloseHandler}>
        <div className={classes.cart__flex}>
          <AiOutlineProfile className={classes.cart__image} />
          <span>Moja Oferta</span>
          <div className={classes.cart__numberbox}>
            <span className={classes.cart__number}>{props.cartInfo.length}</span>
          </div>
        </div>
      </div>
      <div className={contentClassname} ref={sideBarEl}>
        <div className={classes.sidebar__top}>
          <p>
            KOSZYK OFERTOWY <span>({props.cartInfo.length})</span>
          </p>
          <span className={classes.sidebar__close} onClick={props.cartCloseHandler}>
            <AiOutlineClose />
          </span>
        </div>
        <div className={classes.cartitems__container}>
          {props.cartInfo.map((item) => (
            <CartItem key={item.sku} cartInfo={props.cartInfo} item={item} setCartInfo={props.setCartInfo} />
          ))}
        </div>
        <div className={classes.sidebar__footer}>
          <div className={classes.sidebar__button} onClick={props.cartCloseHandler}>
            <Link className={classes.link} to="/podsumowanie">
              PODSUMOWANIE
            </Link>
            <div className={classes.sidebar__span} onClick={props.cartCloseHandler}>
              <span>KONTYNUUJ PRZEGLĄDANIE</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cart;
