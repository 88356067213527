import React, { useEffect, useState } from "react";

import classes from "./Promotion.module.css";
import starlabLogo from "../assets/images/starlab.webp";

// import promotionDoc from "../../download/Promocja Starlab 2023.pdf";


// import abcamLogo from "../assets/images/abcam_2.png";

function Promotion() {
  const [fetchedData, setFetchedData] = useState(Date.now);
  const [fetchedPromoData, setFetchedPromoData] = useState(Date.now);
  const [timerData, setTimerData] = useState({
    days: "0",
    hours: "0",
    days_desc: "Dni",
    hours_desc: "Godzin",
  });
  // const [timerPromoData, setTimerPromoData] = useState({
  //   days: "0",
  //   hours: "0",
  //   days_desc: "Dni",
  //   hours_desc: "Godzin",
  // });

  useEffect(() => {
    fetch("/api")
      .then((res) => res.json())
      .then((data) => setFetchedData(data));
    fetch("/apipromo")
      .then((res) => res.json())
      .then((data) => setFetchedPromoData(data));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const distance = fetchedData - new Date().getTime();
      const daysLeft = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hoursLeft = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) - 1);
      const daysDesc = daysLeft <= 0 ? "Dni" : daysLeft === 1 ? "Dnień" : "Dni";
      const hoursDesc =
        hoursLeft <= 0
          ? "Godzin"
          : hoursLeft === 1
          ? "Godzinę"
          : hoursLeft >= 2 && hoursLeft <= 4
          ? "Godziny"
          : "Godzin";
      setTimerData({
        days: daysLeft <= 0 ? "0" : daysLeft,
        hours: hoursLeft <= 0 ? "0" : hoursLeft,
        days_desc: daysDesc,
        hours_desc: hoursDesc,
      });
    }, 100);
    // const intervalPromo = setInterval(() => {
    //   const distance = fetchedPromoData - new Date().getTime();
    //   const daysLeft = Math.floor(distance / (1000 * 60 * 60 * 24));
    //   const hoursLeft = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) - 1);
    //   const daysDesc = daysLeft <= 0 ? "Dni" : daysLeft === 1 ? "Dnień" : "Dni";
    //   const hoursDesc =
    //     hoursLeft <= 0
    //       ? "Godzin"
    //       : hoursLeft === 1
    //       ? "Godzinę"
    //       : hoursLeft >= 2 && hoursLeft <= 4
    //       ? "Godziny"
    //       : "Godzin";
    //   setTimerPromoData({
    //     days: daysLeft <= 0 ? "0" : daysLeft,
    //     hours: hoursLeft <= 0 ? "0" : hoursLeft,
    //     days_desc: daysDesc,
    //     hours_desc: hoursDesc,
    //   });
    // }, 100);
    return () => {
      clearInterval(interval);
      // clearInterval(intervalPromo);
    };
  }, [fetchedData, fetchedPromoData]);

  return (
    <section className={classes.promotion__wrapper}>
      <div className={classes.flex__wrapper}>
        <div className={classes.promotion__right}>
          <div className={classes.image__container}>
            <img src={starlabLogo} className={classes.starlab__Logo} alt="Logo Starlab" />
          </div>
          {/* <p className={classes.percentage__value}>Do 20% na materiały zużywalne</p>
          <p className={classes.percentage__value2}>Do 15% na drobny sprzęt laboratoryjny</p> */}
          <p className={classes.percentage__value}>Kup pipetę Starlab i ciesz się rabatem do 15% na końcówki</p>
          {/* <a href={promotionDoc} target="_blank" rel="noreferrer" className={classes.promo__button}>
            Zobacz produkty objęte promocją
          </a> */}
          <p className={classes.promotion__title}>Promocja potrwa jeszcze:</p>
          <div className={classes.promotion__timer}>
            <div>
              <p>{timerData.days}</p>
              <p className={classes.timer__desc}>{timerData.days_desc}</p>
            </div>
            <div className={classes.timer__dots}>:</div>
            <div>
              <p>{timerData.hours}</p>
              <p className={classes.timer__desc}>{timerData.hours_desc}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.bottom__divider}></div>
    </section>
  );
}

export default Promotion;
