import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AJena from "../assets/images/ajena.png";
import AMLogo from "../assets/images/activemotif.webp";
import CandorLogo from "../assets/images/logo-candor.svg";
import CyanagenLogo from "../assets/images/cyanagen.webp";
import EverestLogo from "../assets/images/everest.png";
import FineTestLogo from "../assets/images/fntest.webp";
import GeneDirexLogo from "../assets/images/genedirex.webp";
import ICTLogo from "../assets/images/ict.webp";
import IDTLogo from "../assets/images/idt.webp";
import InvitekLogo from "../assets/images/invitek-1.webp";
import MolnovaLogo from "../assets/images/molnova.png";
import OntoresLogo from "../assets/images/ontores.png";
import PrimerDesignLogo from "../assets/images/primer-logo.png";
import React from "react";
import Slider from "react-slick";
import StarlabLogo from "../assets/images/starlab.webp";
import TargetMolLogo from "../assets/images/targetmol.webp";
import UnitmaLogo from "../assets/images/unitma.png";
import abcamLogo from "../assets/images/abcam.webp";
import abtLogo from "../assets/images/ABT_logo.png";
import classes from "./ProductSlider.module.css";

// import LucigenLogo from "../assets/images/Lucigen-min.webp";

export default function SimpleSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: true,
    arrows: false,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  return (
    <section className={classes.slider__wrapper}>
      <Slider {...settings}>
        <div className={classes.image__wrapper}>
          <a href="https://www.abcam.com" target="_blank" rel="noreferrer">
            <img src={abcamLogo} alt="Abcam" className={classes.carousel__image} />
          </a>
        </div>
        <div className={classes.image__wrapper}>
          <a href="https://abtbeads.com/" target="_blank" rel="noreferrer">
            <img src={abtLogo} alt="ABT" className={classes.carousel__image} />
          </a>
        </div>
        <a href="https://www.activemotif.com/" target="_blank" rel="noreferrer">
          <img src={AMLogo} alt="Active Motif" className={classes.carousel__image} />
        </a>
        <a href="https://www.candor-bioscience.de/" target="_blank" rel="noreferrer">
          <img src={CandorLogo} alt="Candor" className={classes.carousel__image} />
        </a>
        <a href="https://www.cyanagen.com/" target="_blank" rel="noreferrer">
          <img src={CyanagenLogo} alt="Cyanagen" className={classes.carousel__image} />
        </a>
        <a href="https://everestbiotech.com/" target="_blank" rel="noreferrer">
          <img src={EverestLogo} alt="Everest Biotech" className={classes.carousel__image} />
        </a>
        <a href="https://www.fn-test.com/" target="_blank" rel="noreferrer">
          <img src={FineTestLogo} alt="Fine-Test" className={classes.carousel__image} />
        </a>
        <a href="https://www.genedirex.com/" target="_blank" rel="noreferrer">
          <img src={GeneDirexLogo} alt="GeneDirex" className={classes.carousel__image} />
        </a>
        <a href="https://www.immunochemistry.com/" target="_blank" rel="noreferrer">
          <img src={ICTLogo} alt="ICT" className={classes.carousel__image} />
        </a>
        <a href="https://eu.idtdna.com/" target="_blank" rel="noreferrer">
          <img src={IDTLogo} alt="IDT" className={classes.carousel__image} />
        </a>
        {/* <a
          href="https://shop.biosearchtech.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={LucigenLogo}
            alt="Lucigen"
            className={classes.carousel__image}
          />
        </a> */}
        <a href="http://www.ontorespeptide.com/" target="_blank" rel="noreferrer">
          <img src={OntoresLogo} alt="Ontores" className={classes.carousel__image} />
        </a>
        <a href="http://www.primerdesign.co.uk/home" target="_blank" rel="noreferrer">
          <img src={PrimerDesignLogo} alt="PrimerDesign" className={classes.carousel__image} />
        </a>
        <a href="https://www.starlabgroup.com/" target="_blank" rel="noreferrer">
          <img src={StarlabLogo} alt="Starlab" className={classes.carousel__image} />
        </a>
        <a href="https://www.uvp.com/" target="_blank" rel="noreferrer">
          <img src={AJena} alt="UVP" className={classes.carousel__image} />
        </a>
        <a href="https://www.targetmol.com/" target="_blank" rel="noreferrer">
          <img src={TargetMolLogo} alt="TargetMol" className={classes.carousel__image} />
        </a>
        <a href="https://www.molnova.com/" target="_blank" rel="noreferrer">
          <img src={MolnovaLogo} alt="Molnova" className={classes.carousel__image} />
        </a>
        <a href="https://www.invitek.com/" target="_blank" rel="noreferrer">
          <img src={InvitekLogo} alt="Invitek" className={classes.carousel__image} />
        </a>
        <a href="http://unitma.com/" target="_blank" rel="noreferrer">
          <img src={UnitmaLogo} alt="Unitma" className={classes.carousel__image} />
        </a>
      </Slider>
    </section>
  );
}
