import React, {useRef, useState} from "react";

import InputField from "./InputField";
import KlauzulaInformacyjna from "../download/Klauzulainformacyjna.pdf";
import {TailSpin} from "react-loader-spinner";
import classes from "./Rodo.module.css";

function PDDForm() {
    const [firstAgreementCheckbox, setFirstAgreementCheckbox] = useState(true);
    const [secondAgreementCheckbox, setsecondAgreementCheckbox] = useState(true);
    const [backMessage, setBackMessage] = useState("");
    const [spinnerActive, setspinnerActive] = useState(false);
    const [variable1] = useState(Math.round(Math.random() * 25));
    const [variable2] = useState(Math.round(Math.random() * 25));

    const captchaInput = useRef(0);

    const [queryData, setQueryData] = useState({
        fname: "",
        lname: "",
        insname: "",
        phone: "",
        email: "",
        firstagreement: true,
        secondagreement: true,
    });

    const inputChangeHandler = (info, indicator) => {
        switch (indicator) {
            case "fname": {
                setQueryData((prev) => ({...prev, fname: info}));
                break;
            }
            case "phone": {
                setQueryData((prev) => ({...prev, phone: info}));
                break;
            }
            case "lname": {
                setQueryData((prev) => ({...prev, lname: info}));
                break;
            }
            case "insname": {
                setQueryData((prev) => ({...prev, insname: info}));
                break;
            }
            case "email": {
                setQueryData((prev) => ({...prev, email: info}));
                break;
            }
            case "secondagreement": {
                setQueryData((prev) => ({...prev, secondagreement: info}));
                break;
            }
            default:
                break;
        }
    };

    const sendmail = (e) => {
        e.preventDefault();
        if (
            Number(captchaInput.current.value) === variable1 + variable2 &&
            queryData.email.length !== 0 &&
            queryData.fname.length !== 0 &&
            queryData.lname.length !== 0 &&
            queryData.insname.length !== 0
        ) {
            setspinnerActive(true);
            window.Email.send({
                SecureToken: "ef532d75-22a3-4326-91a9-089b622e0b82",
                From: "Website@symbios.pl",
                To: "Website@symbios.pl",
                Subject: "Zgoda RODO",
                Body: `Imię: ${queryData.fname} <br/> Nazwisko: ${queryData.lname} <br/> Instytucja: ${
                    queryData.insname
                } <br/> E-mail: ${queryData.email} <br/>Numer tel: ${
                    queryData.phone
                } <br/> Zgody: Wyrażam zgodę na przetwarzanie moich danych osobowych w celu przygotowania, złożenia i realizacji ofert i/lub umów handlowych dotyczących produktów do badań i diagnostyki z obszaru „life science” przez Symbios Sp. z o.o. Jednocześnie oświadczam, że zapoznałam/em się z treścią klauzuli informacyjnej. [${
                    queryData.firstagreement ? "TAK" : "NIE"
                }] <br/> Wyrażam zgodę na przetwarzanie moich danych osobowych w celu promocji, reklamy produktów i usług Symbios Sp. z o.o. w tym poprzez wykorzystywanie telekomunikacyjnych urządzeń końcowych (kontakt mailowy i telefoniczny) oraz automatycznych systemów wywołujących dla celów marketingu bezpośredniego Symbios zgodnie z Ustawą z dnia 16 lipca 2004 r. Prawo telekomunikacyjne. [${
                    queryData.secondagreement ? "TAK" : "NIE"
                }]`,
            }).then((message) => {
                setBackMessage(message);
                setspinnerActive(false);
                if (message === "OK") localStorage.setItem("cart", "[]");
            });
        } else {
            setBackMessage("empty fields");
            setspinnerActive(false);
        }
    };

    return (
        <section className={classes.rodo__form}>
            <h2 className={classes.rodo__heading}>Formularz przystapienia do Programu Dla Doktorantow (PDD)</h2>
            <form action="" method="">
                <div className={classes.container__row}>
                    <InputField
                        text="Imię i Nazwisko"
                        type="text"
                        name="fname"
                        required="true"
                        autocomplete="on"
                    />
                    <InputField
                        text="Data przystapienia do PDD"
                        type="date"
                        name="lname"
                        required="true"
                        autocomplete="on"
                    />
                </div>
                <div className={classes.container__row}>
                    <InputField
                        text="Tytul, Imie i Nazwisko Promotora"
                        type="text"
                        name="insname"
                        required="true"
                        autocomplete="on"
                    />
                    <InputField
                        text="Zaklad"
                        type="text"
                        name="email"
                        required="true"
                        autocomplete="on"
                    />
                </div>
                <div className={classes.container__row}>
                    <InputField
                        text="Adres zakladu"
                        type="text"
                        name="insname"
                        required="true"
                        autocomplete="on"
                        inputChangeHandler={inputChangeHandler}
                    />
                    <InputField
                        text="Adres e-mail"
                        type="email"
                        name="email"
                        required="true"
                        autocomplete="on"
                        inputChangeHandler={inputChangeHandler}
                    />
                </div>
                <div className={classes.container__row}>
                    <InputField
                        text="telefon"
                        type="tel"
                        name="insname"
                        required="true"
                        autocomplete="on"
                    />
                    <InputField
                        text="kierownik zakladu"
                        type="text"
                        name="email"
                        required="true"
                        autocomplete="on"

                    />
                </div>
                <div className={`${classes.container__row} ${classes["container__row"]}`}>
                    <InputField
                        text="Skład grupy asystenckiej i/lub doktoranckiej"
                        type="text"
                        name="phone"
                        required="true"
                        autocomplete="on"
                        inputChangeHandler={inputChangeHandler}
                    />
                </div>
                <div className={`${classes.container__row} ${classes["container__row"]}`}>
                    <p> Skala badań:</p>
                    <input type="checkbox" id="diagnostyka" name="scales" checked/>
                    <label htmlFor="diagnostyka">diagnostyka</label>

                    <input type="checkbox" id="badania naukowe" name="badania naukowe" checked/>
                    <label htmlFor="scales">badania naukowe</label>

                    <input type="checkbox" id="grant EU" name="grant EU" checked/>
                    <label htmlFor="grant EU">grant EU</label>

                    <input type="checkbox" id="grant NCN" name="grant NCN" checked/>
                    <label htmlFor="grant NCN">grant NCN</label>
                </div>
                <div className={`${classes.container__row} ${classes["container__row"]}`}>
                    <InputField
                        text="Tematyka:"
                        type="text"
                        name="phone"
                        required="true"
                        autocomplete="on"
                    />
                </div>
                <div className={`${classes.container__row} ${classes["container__row"]}`}>
                    <p>Cechy produktów istotne dla klienta:</p>
                    <input type="checkbox" id="diagnostyka" name="scales" checked/>
                    <label htmlFor="diagnostyka">Łatwość użycia</label>

                    <input type="checkbox" id="badania naukowe" name="badania naukowe" checked/>
                    <label htmlFor="scales">Jakość</label>

                    <input type="checkbox" id="grant EU" name="grant EU" checked/>
                    <label htmlFor="grant EU">Możliwość kompleksowego zakupu w jednej
                        firmie</label>

                    <input type="checkbox" id="grant NCN" name="grant NCN" checked/>
                    <label htmlFor="grant NCN">Szczególne parametry produktu</label>
                </div>
              <br/>
                <div className={`${classes.container__row} ${classes["container__row"]}`}>
                    <p>Jestem zainteresowany informacjami na temat kolejnych akcji promocyjnych :</p>
                    <input type="checkbox" id="diagnostyka" name="scales" checked/>
                    <label htmlFor="diagnostyka">Tak</label>

                    <input type="checkbox" id="badania naukowe" name="badania naukowe" checked/>
                    <label htmlFor="scales">Nie</label>

                </div>
              <br/>
                <div className={`${classes.container__row} ${classes["container__row"]}`}>
                    <p>Jestem zainteresowany otrzymywaniem literatury i katalogów związanych tematycznie z zakresem
                        mojej
                        pracy</p>
                    <input type="checkbox" id="diagnostyka" name="scales" checked/>
                    <label htmlFor="diagnostyka">Tak</label>

                    <input type="checkbox" id="badania naukowe" name="badania naukowe" checked/>
                    <label htmlFor="scales">Nie</label>
                </div>
              <br/>
                <div className={classes["container__row--textarea"]}>
                    <div className={classes.label__box}>
                        <input
                            type="checkbox"
                            id="firstagreement"
                            name="firstagreement"
                            value={firstAgreementCheckbox}
                            checked={firstAgreementCheckbox}
                            onChange={(e) => {
                                setFirstAgreementCheckbox(firstAgreementCheckbox);
                                inputChangeHandler(e.target.checked, e.target.name);
                            }}
                        />
                        <label htmlFor="firstagreement">
                            Wyrażam zgodę na przetwarzanie moich danych osobowych w celu przygotowania, złożenia i
                            realizacji ofert
                            i/lub umów handlowych dotyczących produktów do badań i diagnostyki z obszaru „life science”
                            przez Symbios
                            Sp. z o.o. Jednocześnie oświadczam, że zapoznałam/em się z treścią{" "}
                            <a href={KlauzulaInformacyjna} rel="noreferrer" target="_blank">
                                klauzuli informacyjnej.
                            </a>
                            *
                        </label>
                    </div>
                    <div className={classes.label__box}>
                        <input
                            type="checkbox"
                            id="secondagreement"
                            name="secondagreement"
                            value={secondAgreementCheckbox}
                            checked={secondAgreementCheckbox}
                            onChange={(e) => {
                                setsecondAgreementCheckbox(!secondAgreementCheckbox);
                                inputChangeHandler(e.target.checked, e.target.name);
                            }}
                        />
                        <label htmlFor="secondagreement">
                            Wyrażam zgodę na przetwarzanie moich danych osobowych w celu promocji, reklamy produktów i
                            usług Symbios
                            Sp. z o.o. w tym poprzez wykorzystywanie telekomunikacyjnych urządzeń końcowych (kontakt
                            mailowy i
                            telefoniczny) oraz automatycznych systemów wywołujących dla celów marketingu bezpośredniego
                            Symbios
                            zgodnie z Ustawą z dnia 16 lipca 2004 r. Prawo telekomunikacyjne. (opcjonalne)
                        </label>
                    </div>
                </div>
                <div className={classes.captcha__styling}>
                    <label htmlFor="secondagreement">Ochrona anty-spamowa:</label>
                    <div className={classes.captcha__checker}>
                        <span>{variable1}</span> + <span>{variable2} = </span>
                        <input type="number" ref={captchaInput} className={classes.captcha__input}/>
                    </div>
                    {spinnerActive && (
                        <TailSpin
                            height="40"
                            width="40"
                            color="#1c3975"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{marginTop: "2rem"}}
                            wrapperClass=""
                            visible={true}
                        />
                    )}

                    <div className={classes.message__output}>
                        {backMessage === "OK" ? (
                            <h3 className={classes["checkout__bigtext--h3"]}>Zgoda RODO została pomyślnie wysłana</h3>
                        ) : backMessage === "empty fields" ? (
                            <h3 className={classes["checkout__bigtext--h3"]}>Wszystkie pola oznaczone * muszą zostać
                                wypełnione</h3>
                        ) : backMessage === "" ? (
                            ""
                        ) : (
                            <h3 className={classes["checkout__bigtext--h3"]}>
                                Wystąpił błąd, spróbuj ponownie póżniej lub skontaktuj się z odpowiedzialnym za Twój
                                rynek doradcą
                            </h3>
                        )}
                    </div>
                </div>
            </form>
        </section>
    );
}

export default PDDForm;
