import React from "react";
import { Link } from "react-router-dom";
import classes from "./Error.module.css";
import MainNavigation from "../components/MainNavigation";
import Footer from "../components/footer/Footer";
import { MdError } from "react-icons/md";

function Error() {
  return (
    <div>
      <MainNavigation />
      <main className={classes.main__wrapper}>
        <MdError />
        <h1 className={classes.heading__main}>
          Strona, której szukasz, nie istnieje.
        </h1>
        <p>Odwiedź pozostałe podstrony lub skontaktuj się z nami:</p>
        <div className={classes.columns__wrapper}>
          <div className={classes.column__left}>
            <Link to="">start</Link>
            <Link to="">o firmie</Link>
            <Link to="">oferta</Link>
            <Link to="">partnerzy</Link>
          </div>
          <div className={classes.column__right}>
            <Link to="">wsparcie</Link>
            <Link to="">kontakt</Link>
            <Link to="">PDD</Link>
            <Link to="">dołącz do nas</Link>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Error;
