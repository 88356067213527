export const PDDData1 = [
  {
    id: 1,
    question: "Nie jestem doktorantem, czy program jest również dla mnie?",
    answer: `<p>Oczywiście, program kierowany jest do wszystkich naukowców zdolnych do podejmowania samodzielnych decyzji zakupowych. Nazwa programu jest historyczna i nie wskazuje żadnych ograniczeń</p>`,
  },
  {
    id: 2,
    question: "Jakie firmy biorą udział w programie?",
    answer: `<p>Bonus wynikający z zapisów programu przyznawany jest za zakup asortymentu (bez rabatów lub umów) następujących firm: ABT, Antibodies.com, Candor, Cyanagen, Everest Biotech, Finetest, GeneDireX, ICT (Immunochemistry Technologies), Starlab (z wyłączeniem urządzeń)</p>`,
  },
  {
    id: 3,
    question: "Czy umowa między moją instytucją a firmą Symbios wyklucza udział w programie?",
    answer: `<p>Tak, bonus naliczany jest tylko od zakupów nie objętych umowami oraz innymi promocjami lub rabatami</p>`,
  },
];
export const PDDData2 = [
  {
    id: 1,
    question: "Czy przystąpienie do programu wiąże się z jakąkolwiek deklaracją zakupową z mojej strony?",
    answer: `<p>Nie, udział w programie nie wiąże się z określeniem zapotrzebowania ani nie narzuca obowiązku zakupu</p>`,
  },
  {
    id: 2,
    question: "Czy udział w programie musi być deklarowany co roku?",
    answer: `<p>Tak, deklaracja przewiduje udział w programie do końca roku kalendarzowego, wraz z nowym rokiem należy ją więc odnowić.</p>`,
  },
  {
    id: 3,
    question: "Na co mogę wykorzystać uzyskany bonus?",
    answer: `<p>Bonus wykorzystać można na wiele sposobów, takich jak: </br></p><ul><li>dofinansowanie wyjazdów na seminaria i konferencje</li><li>rabat na zakup sprzętu firm Starlab, 3DMed, Invitek, Unitma</li><li>można otrzymać produkty z oferty Symbios lub rabat na nie w wysokości bonusu</li><li>można za pośrednictwem Symbios Sp. z o.o dokonać zakupu potrzebnych do pracy naukowej przedmiotów niewystępujących w ofercie Symbios – zgodnie z pkt. 8 regulaminu Programu</li><li>można uzyskać pokrycie kosztów innych uzasadnionych wydatków związanych z prowadzoną działalnością badawczo-naukową</li></ul>`,
  },
];
