import React from "react";
import SearchListItem from "./SearchListItem";
import classes from "./SearchList.module.css";
function SearchList(props) {
  let outputData = {};
  if (props.items) outputData = Object.values(props.items);

  const renderItem = () => {
    if (outputData) {
      return (
        <div
          data-type="search__res"
          className={
            outputData
              ? classes["search__container--active"]
              : classes["search__container"]
          }
          onMouseDown={props.inputClickHandler}
        >
          {outputData.map((output) => {
            if (Object.keys(output).length !== 0) {
              return (
                <SearchListItem
                  key={output.id}
                  items={output}
                  addToCartHandler={props.addToCartHandler}
                  cartCloseHandler={props.cartCloseHandler}
                />
              );
            } else return "bra";
          })}
        </div>
      );
    } else {
      return;
    }
  };
  return renderItem();
}

export default SearchList;
