import React from "react";
import classes from "./Home.module.css";
import Carousel from "../components/carousel/Carousel";
import Promotion from "../components/promotion/Promotion";
import ProducentSlider from "../components/producentSlider/ProducentSlider";
// import HomeSection from "../components/homeSection/HomeSectior";
import Rodo from "../components/Rodo";
function Home() {
  return (
    <div className={classes.pagecontent__container}>
      <Carousel />
      {/*<Promotion />*/}
      <Rodo />
      {/* <HomeSection /> */}
      <ProducentSlider />
    </div>
  );
}

export default Home;
