import React from "react";
import classes from "./Wsparcie.module.css";
import { Accordion } from "../components/Accordion";
import WsparcieSection1 from "../components/WsparcieSection1";
import WsparcieSection2 from "../components/WsparcieSection2";
import image from "../components/assets/images/7.jpg";
import image2 from "../components/assets/images/8.jpg";
import image3 from "../components/assets/images/9.jpg";
import image4 from "../components/assets/images/10.jpg";
import { section3Data } from "./WsparcieData";
import { section4Data } from "./WsparcieData";
import { section5Data } from "./WsparcieData";
import { section6Data } from "./WsparcieData";
import WsparcieSection3 from "../components/WsparcieSection3";
function Wsparcie() {
  return (
    <div className={classes.main__wrapper}>
      <WsparcieSection1 />
      <WsparcieSection2 />
      <section className={classes.section__wrapper}>
        <div className={classes.column__left}>
          <p className={classes.left__firstparagraph}>PROGRAM LOJALNOŚCIOWY</p>
          <h1>Program Dla Doktorantów</h1>
          <hr />
          <p className={classes.left_lastparagraph}>
            Jest to Program skierowany do grup badawczych, skupiających w swoim
            gronie Naukowców w tym: Profesorów, Doktorów, Doktorantów,
            Asystentów współpracujących z Firmą Symbios.
          </p>
          <div className={classes.accordion__container}>
            {section3Data.map((section, index) => (
              <Accordion key={index} section={section} />
            ))}
          </div>
        </div>
        <div className={classes.column__right}>
          <img src={image} alt="Zdjęcie probówek w statywie" />
        </div>
      </section>
      <section className={classes.section__wrapper__reverse}>
        <div className={classes.column__left__reverse}>
          <div className={classes["column__right--section4"]}>
            <img src={image2} alt="Zdjęcie probówek w statywie" />
          </div>
        </div>
        <div className={classes.column__right__reverse}>
          <div className={classes.accordion__container__reverse}>
            {section4Data.map((section, index) => (
              <Accordion key={index} section={section} />
            ))}
          </div>
        </div>
      </section>
      <section className={classes.section__wrapper}>
        <div className={classes.column__left}>
          <div className={classes.accordion__container}>
            {section5Data.map((section, index) => (
              <Accordion key={index} section={section} />
            ))}
          </div>
        </div>
        <div className={classes.column__right}>
          <img src={image3} alt="Zdjęcie probówek w statywie" />
        </div>
      </section>
      <section className={classes.section__wrapper__reverse}>
        <div className={classes.column__left__reverse}>
          <div className={classes["column__right--section4"]}>
            <img src={image4} alt="Zdjęcie probówek w statywie" />
          </div>
        </div>
        <div className={classes.column__right__reverse}>
          <div className={classes.accordion__container__reverse}>
            {section6Data.map((section, index) => (
              <Accordion key={index} section={section} />
            ))}
          </div>
        </div>
      </section>
      <WsparcieSection3 />
    </div>
  );
}

export default Wsparcie;
