import React, {useRef, useState, useEffect} from "react";
import {Link, NavLink} from "react-router-dom";
import classes from "./MainNavigation.module.css";
import image from "./assets/images/logo_main.webp";
import SearchList from "./SearchList";
import SearchNoRes from "./SearchNoRes";
import {NavSpinner} from "./navSpinner/navSpinner";
import {TailSpin} from "react-loader-spinner";

function MainNavigation(props) {
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    const searchInputRefuse = useRef([]);
    const [data, setData] = useState({});
    const [mobileOpen, setMobileOpen] = useState(false);
    const mobileOpenHandler = () => {
        if (mobileOpen) setMobileOpen(false);
        else setMobileOpen(true);
    };
    const handleInputChange = (event) => {
        setLoading(false)
        if (event.target.value.length > 2) setLoadingSpinner(true)
        setSearchTerm(event.target.value);
    };
    // const fetchData = () => {
    //   clearTimeout(debounceTimeout);
    //
    //   if (searchInputRefuse.current.value.length > 2)
    //     fetch("/api", {
    //       method: "POST",
    //       body: JSON.stringify({
    //         title: searchInputRefuse.current.value,
    //       }),
    //       headers: {
    //         "Content-type": "application/json; charset=UTF-8",
    //       },
    //     })
    //       .then((res) => res.json())
    //       .then((data) => setData(data));
    //   else {
    //     setData({});
    //   }
    // };
    useEffect(() => {

        const debounceTimeout = setTimeout(() => {
            setLoading(false)

            if (searchTerm.length > 2) {
                fetch("/api", {
                    method: "POST",
                    body: JSON.stringify({
                        title: searchTerm,
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                })
                    .then((res) => res.json())
                    .then((data) => {
                        setLoadingSpinner(false)
                        setData(data)
                        setLoading(true)
                    });
            } else {
                setData({});
            }
        }, 300); // Adjust the debounce delay as needed

        // Cleanup function to clear the timeout if the searchTerm changes before the timeout completes
        return () => clearTimeout(debounceTimeout);
    }, [searchTerm]);
    return (
        <header className={classes.main__header}>
            <div className={classes.header__wrapper}>
                <div className={classes.header__image}>
                    <Link to="/">
                        <img src={image} alt="Main Logo"/>
                    </Link>
                </div>
                <div className={classes.header__navigation}>
                    <div className={classes.navigation__wrapper}>
                        <div>
                            <nav className={classes.Navbar}>
                                <ul className={mobileOpen ? `${classes.mobile__open}` : ""}>
                                    <li>
                                        <NavLink to="/"
                                                 className={({isActive}) => (isActive ? classes.active : undefined)}
                                                 onClick={mobileOpenHandler} end>
                                            start
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/o-firmie"
                                                 className={({isActive}) => (isActive ? classes.active : undefined)}
                                                 onClick={mobileOpenHandler}>
                                            o firmie
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/oferta"
                                                 className={({isActive}) => (isActive ? classes.active : undefined)}
                                                 onClick={mobileOpenHandler}>
                                            oferta
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/Partnerzy"
                                                 className={({isActive}) => (isActive ? classes.active : undefined)}
                                                 onClick={mobileOpenHandler}>
                                            partnerzy
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/Wsparcie"
                                                 className={({isActive}) => (isActive ? classes.active : undefined)}
                                                 onClick={mobileOpenHandler}>
                                            wsparcie
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/Kontakt"
                                                 className={({isActive}) => (isActive ? classes.active : undefined)}
                                                 onClick={mobileOpenHandler}>
                                            kontakt
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/PDD"
                                                 className={({isActive}) => (isActive ? classes.active : undefined)}
                                                 onClick={mobileOpenHandler}>
                                            PDD
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/DolaczDoNas"
                                                 className={({isActive}) => (isActive ? classes.active : undefined)}
                                                 onClick={mobileOpenHandler}>
                                            dołacz do nas
                                        </NavLink>
                                    </li>
                                </ul>
                                <div className={classes.hamburger__menu}>
                                    <div onClick={mobileOpenHandler}>
                                        <span className={classes.hamburger__bar}></span>
                                        <span className={classes.hamburger__bar}></span>
                                        <span className={classes.hamburger__bar}></span>
                                    </div>
                                </div>
                            </nav>
                        </div>
                        <div className={classes.navigation__search}>
                            <div className={classes.dropdown__menu}>
                                <button className={classes.dropdown__button}>
                                    <p className={classes.button__paragraph}>
                                        Kategorie produktów
                                        <span>
                      <svg fill="#000000" width="800px" height="800px" viewBox="0 0 32 32"
                           xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M 4.21875 10.78125 L 2.78125 12.21875 L 15.28125 24.71875 L 16 25.40625 L 16.71875 24.71875 L 29.21875 12.21875 L 27.78125 10.78125 L 16 22.5625 Z"/>
                      </svg>
                    </span>
                                    </p>
                                </button>
                                <div className={classes.dropdown__content}>
                                    <div className={classes.dropdown__row}>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Analiza_chromatyny" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Analiza chromatyny
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Izolacja_DNA_RNA" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Izolacja DNA/RNA
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/NGS" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                NGS
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Sprzet" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Sprzęt
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className={classes.dropdown__separator}/>
                                    <div className={classes.dropdown__row}>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Barwniki" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Barwniki
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink
                                                to="/Kategoria/Klonowanie_i_ekspresja_bialek"
                                                reloadDocument
                                                className={({isActive}) => (isActive ? classes.active : undefined)}
                                            >
                                                Klonowanie i ekspresja białek
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Obrazowanie" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Obrazowanie
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Substraty" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Substraty
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className={classes.dropdown__separator}/>
                                    <div className={classes.dropdown__row}>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Bialka_i_peptydy" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Białka i peptydy
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Koncowki_do_pipet" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Końcówki do pipet
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Oligonukleotydy" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Oligonukleotydy
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink
                                                to="/Kategoria/Testy_przezywalności_komorkowej"
                                                reloadDocument
                                                className={({isActive}) => (isActive ? classes.active : undefined)}
                                            >
                                                Testy przeżywalności komórkowej
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className={classes.dropdown__separator}/>
                                    <div className={classes.dropdown__row}>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Bufory" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Bufory
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Linie_komorkowe_i_lizaty" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Linie komórkowe i lizaty
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/PCR_qPCR" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                PCR/qPCR
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/WB" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                WB
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className={classes.dropdown__separator}/>
                                    <div className={classes.dropdown__row}>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/CHIP" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                CHIP
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Materialy_ochronne" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Materiały ochronne
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Pipety" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Pipety
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Zestawy_do_Elisa" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Zestawy do Elisa
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className={classes.dropdown__separator}/>
                                    <div className={classes.dropdown__row}>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/CRISPR" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                CRISPR
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Materialy_zuzywalne" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Materiały zużywalne
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Przeciwciala" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Przeciwciała
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Zestawy_do_IHC" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Zestawy do IHC
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className={classes.dropdown__separator}/>
                                    <div className={classes.dropdown__row}>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Enzymy" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Enzymy
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Media" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Media
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Regulacja_genow" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Regulacja genów
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Zloza_i_kolumny" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Złoża i kolumny
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className={classes.dropdown__separator}/>
                                    <div className={classes.dropdown__row}>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Geny_i_fragmenty_genow" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Geny i fragmenty genów
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Metylacja_DNA" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Metylacja DNA
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Sars-CoV-2" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Sars-CoV-2
                                            </NavLink>
                                        </div>
                                        <div className={classes.dropdown__column}>
                                            <NavLink to="/Kategoria/Zwiazki_chemiczne" reloadDocument
                                                     className={({isActive}) => (isActive ? classes.active : undefined)}>
                                                Związki chemiczne
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes["navigation__input--container"]}>
                                <svg version="1.1" x="0px" y="0px" viewBox="0 0 51.539 51.361">
                                    <path
                                        fill="#444"
                                        d="M51.539,49.356L37.247,35.065c3.273-3.74,5.272-8.623,5.272-13.983c0-11.742-9.518-21.26-21.26-21.26 S0,9.339,0,21.082s9.518,21.26,21.26,21.26c5.361,0,10.244-1.999,13.983-5.272l14.292,14.292L51.539,49.356z M2.835,21.082 c0-10.176,8.249-18.425,18.425-18.425s18.425,8.249,18.425,18.425S31.436,39.507,21.26,39.507S2.835,31.258,2.835,21.082z"
                                    ></path>
                                </svg>
                                <input data-type="search__res" type="text" placeholder="Wyszukaj po numerze katalogowym"
                                       ref={searchInputRefuse} onChange={handleInputChange}/>

                                {loadingSpinner && <TailSpin
                                    visible={true}
                                    height="80"
                                    width="80"
                                    color="#1C3875"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{position: "absolute", height: "20px", width: "20px", right: "20px"}}
                                    wrapperClass=""
                                />}

                            </div>
                            {Object.keys(data).length === 0 && loading && props.openSearch && searchInputRefuse.current.value.length > 2 &&
                                <SearchNoRes/>}
                            {Object.keys(data).length !== 0 && props.openSearch && (
                                <SearchList
                                    items={data}
                                    addToCartHandler={props.addToCartHandler}
                                    setOpenSearch={props.setOpenSearch}
                                    cartCloseHandler={props.cartCloseHandler}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default MainNavigation;
