import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import OFirmie from "./pages/OFirmie";
import Home from "./pages/Home";
import Oferta from "./pages/Oferta";
import Partnerzy from "./pages/Partnerzy";
import Wsparcie from "./pages/Wsparcie";
import Kontakt from "./pages/Kontakt";
import PDD from "./pages/PDD";
import DolaczDoNas from "./pages/DolaczDoNas";
import Root from "./components/Root";
import Podsumowanie from "./pages/Podsumowanie";
import Error from "./pages/Error";
import Kategorie from "./pages/Kategorie";
import Instructions from "./components/Instructions";
import PDDForm from "./components/PDDForm";
const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <Error />,
    element: <Root />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/o-firmie", element: <OFirmie /> },
      { path: "/oferta", element: <Oferta /> },
      { path: "/Partnerzy", element: <Partnerzy /> },
      { path: "/Wsparcie", element: <Wsparcie /> },
      { path: "/Kontakt", element: <Kontakt /> },
      { path: "/PDD", element: <PDD /> },
      { path: "/DolaczDoNas", element: <DolaczDoNas /> },
      { path: "/Podsumowanie", element: <Podsumowanie /> },
      { path: "/Kategoria/:group", element: <Kategorie /> },
      { path: "/Instrukcja", element: <Instructions /> },
      { path: "/a1652ec2", element: <PDDForm /> },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
