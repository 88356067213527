import React, { useState, useEffect } from "react";
import MainNavigation from "./MainNavigation";
import { Outlet } from "react-router-dom";
import classes from "./Root.module.css";
import Cart from "./cart/Cart";
import Footer from "./footer/Footer";
import ScrollToTop from "./ScrollToTop";
function Root() {
  const [openSearch, setOpenSearch] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [cartInfo, setCartInfo] = useState(JSON.parse(localStorage.getItem("cart")) || []);
  const localCart = localStorage.getItem("cart");

  useEffect(() => {
    document.body.addEventListener("mousedown", inputClickHandler);
    if (cartOpen) {
      document.body.classList.add(`${classes.main__overflow}`);
    } else {
      document.body.classList.remove(`${classes.main__overflow}`);
    }
  }, [cartOpen]);

  const inputClickHandler = (e) => {
    if (e.target.getAttribute("data-type") === "search__res") {
      setOpenSearch(true);
    } else {
      setOpenSearch(false);
    }
  };

  const addToCartHandler = (title, sku, producent) => {
    if (cartInfo.some((item) => item.sku === sku)) {
      return;
    } else {
      setCartInfo([...cartInfo, { title, sku, amount: 1, producent }]);
    }
  };

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartInfo));
  }, [cartInfo]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCartInfo(JSON.parse(localStorage.getItem("cart")));
    }, 1000);
    return () => clearInterval(interval);
  }, [localCart]);

  const cartCloseHandler = () => {
    if (cartOpen) {
      setCartOpen(false);
    } else {
      setCartOpen(true);
    }
  };

  return (
    <div>
      <ScrollToTop />
      <MainNavigation openSearch={openSearch} cartInfo={cartInfo} addToCartHandler={addToCartHandler} cartCloseHandler={cartCloseHandler} />
      <main className={cartOpen ? `${classes.main__element} ${classes.main__overflow}` : classes.main__element}>
        <Outlet />
      </main>
      <Cart cartInfo={cartInfo} addToCartHandler={addToCartHandler} setCartInfo={setCartInfo} cartCloseHandler={cartCloseHandler} cartOpen={cartOpen} />
      <Footer />
    </div>
  );
}

export default Root;
