import classes from "./Wsparcie.module.css";
import IBBDoc1 from "../download/Opinions/IBB1.pdf";
import IBBDoc2 from "../download/Opinions/IBB2.pdf";
import IBBDoc3 from "../download/Opinions/IBB3.pdf";
import IBBDoc4 from "../download/Opinions/IBB4.pdf";
import SUWKDoc1 from "../download/Opinions/SUWK1.pdf";
import SUWKDoc2 from "../download/Opinions/SUWK2.pdf";
import UMSKDoc1 from "../download/Opinions/UMSK1.pdf";
import UGDoc1 from "../download/Opinions/UG1.pdf";
import UGIHZDoc1 from "../download/Opinions/UGIHZ1.pdf";
import IFPANDoc1 from "../download/Opinions/IFPAN1.pdf";
import IFPANDoc2 from "../download/Opinions/IFPAN2.pdf";
import IBMPANDoc1 from "../download/Opinions/IBMPAN1.pdf";
import UMLDoc1 from "../download/Opinions/UML1.pdf";
import UMLDoc2 from "../download/Opinions/UML2.pdf";
import ICBPANDoc1 from "../download/Opinions/ICBPAN1.pdf";
import IGCPANWPDoc1 from "../download/Opinions/IGCPANWP1.pdf";
import ULDoc1 from "../download/Opinions/UL1.pdf";
import ULDoc2 from "../download/Opinions/UL2.pdf";
import ULDoc3 from "../download/Opinions/UL3.pdf";
import UWBDoc1 from "../download/Opinions/UWB1.pdf";
import UMWLDoc1 from "../download/Opinions/UMWL1.pdf";
import UMWLDoc2 from "../download/Opinions/UMWL2.pdf";
import GIORINDoc1 from "../download/Opinions/GIORIN1.pdf";
import NIZPPZHDoc1 from "../download/Opinions/NIZPPZH1.pdf";
import IIITDPANDoc1 from "../download/Opinions/IIITDPAN1.pdf";
import IIITDPANDoc2 from "../download/Opinions/IIITDPAN2.pdf";
import WUMDoc1 from "../download/Opinions/WUM1.pdf";
import WUMDoc2 from "../download/Opinions/WUM2.pdf";
import WPLFAMWWDoc1 from "../download/Opinions/WPLFAMWW1.pdf";
import IPCZDDoc1 from "../download/Opinions/IPCZD1.pdf";
import ZGSCDoc1 from "../download/Opinions/ZGSC1.pdf";
import KWPWKDoc1 from "../download/Opinions/KWPK1.pdf";
import UWDoc1 from "../download/Opinions/UW1.pdf";
import IBDDoc1 from "../download/Opinions/IBD1.pdf";
import ICBDoc1 from "../download/Opinions/ICB1.pdf";
import GUMDoc1 from "../download/Opinions/GUM1.pdf";
import CPFDoc1 from "../download/Opinions/CPF1.pdf";
export const section3Data = [
  {
    id: 1,
    question: "Instytut Biochemii i Biofizyki",
    answer: `<a href="${IBBDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Opinia – NxSeq Long Mate Pair Library Kit</a>
               <a href="${IBBDoc2}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>
               <a href="${IBBDoc3}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>
               <a href="${IBBDoc4}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Oświadczenie</a>`,
  },
  {
    id: 2,
    question: "Szpital Uniwersytecki w Krakowie",
    answer: `<a href="${SUWKDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Opinia – Arrow Blood DNA 200 oraz CellSep Advanced</a>
               <a href="${SUWKDoc2}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- List referencyjny – Diasorin</a>`,
  },
  {
    id: 3,
    question: "Uniwersytet M.Curie-Skłodowskiej w Lublinie",
    answer: `<a href="${UMSKDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Opinia – serwis pipet</a>`,
  },
  {
    id: 4,
    question: "Uniwersytet Gdański",
    answer: `<a href="${UGDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Opinia – GenAsia</a>`,
  },
  {
    id: 5,
    question: "Instytut Genetyki i Hodowlii Zwierząt PAN",
    answer: `<a href="${UGIHZDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje – Starlab</a>`,
  },
];
export const section4Data = [
  {
    id: 6,
    question: "Instytut Farmakologii Polskiej Akademii Nauk",
    answer: `<a href="${IFPANDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>
               <a href="${IFPANDoc2}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>`,
  },
  {
    id: 7,
    question: "Instytut Biologii Medycznej PAN",
    answer: `<a href="${IBMPANDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>`,
  },
  {
    id: 8,
    question: "Uniwersytet Medyczny w Łodzi",
    answer: `<a href="${UMLDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>
               <a href="${UMLDoc2}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Oświadczenie</a>`,
  },
  {
    id: 9,
    question: "Instytut Chemii Bioorganicznej Polskiej Akademii Nauk",
    answer: `<a href="${ICBPANDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>`,
  },
  {
    id: 10,
    question: "Instytut Genetyki Człowieka Polskiej Akademii Nauk w Poznaniu",
    answer: `<a href="${IGCPANWPDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>`,
  },
  {
    id: 11,
    question: "Uniwersytet Łódzki",
    answer: `<a href="${ULDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Oświadczenie</a>
               <a href="${ULDoc2}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Oświadczenie</a>
               <a href="${ULDoc3}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Podziękowania</a>`,
  },
  {
    id: 12,
    question: "Uniwersytet w Białymstoku",
    answer: `<a href="${UWBDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Oświadczenie</a>`,
  },
  {
    id: 13,
    question: "Uniwersytet Medyczny w Lublinie",
    answer: `<a href="${UMWLDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>
               <a href="${UMWLDoc2}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Oświadczenie</a>`,
  },
];

export const section5Data = [
  {
    id: 14,
    question: "Główny Inspektoriat Ochrony Roślin i Nasiennictwa",
    answer: `<a href="${GIORINDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>`,
  },
  {
    id: 15,
    question:
      "Narodowy Instytut Zdrowia Publicznego - Państwowy Zakład Hiegieny",
    answer: `<a href="${NIZPPZHDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>`,
  },
  {
    id: 16,
    question:
      "Instytut Immunologii i Terapii Doświadczalnej PAN im. Ludwika Hirszfelda",
    answer: `<a href="${IIITDPANDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>
                 <a href="${IIITDPANDoc2}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Oświadczenie</a>`,
  },
  {
    id: 17,
    question: "Warszawski Uniwersytet Medyczny",
    answer: `<a href="${WUMDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>
             <a href="${WUMDoc2}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>`,
  },
  {
    id: 18,
    question:
      "Wielospecjalistyczna Przychodnia Lekarska, Fundacja Akedmiii Medycznej we Wrocławiu",
    answer: `<a href="${WPLFAMWWDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>`,
  },
  {
    id: 19,
    question: 'Instytut "Pomnik-Centrum Zdrowia Dziecka"',
    answer: `<a href="${IPCZDDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Oświadczenie</a>`,
  },
  {
    id: 20,
    question: "Zakład genetyki Sądowej Celestynów",
    answer: `<a href="${ZGSCDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Oświadczenie</a>`,
  },
  {
    id: 21,
    question: "Komenda Wojewódzka Policji w Katowicach",
    answer: `<a href="${KWPWKDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>`,
  },
];
export const section6Data = [
  {
    id: 22,
    question: "Uniwersytet Warszawski",
    answer: `<a href="${UWDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>`,
  },
  {
    id: 23,
    question: "Instytut Biologii Doświadczalnej",
    answer: `<a href="${IBDDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>`,
  },
  {
    id: 24,
    question: "Instytut Chemii Bioorganicznej",
    answer: `<a href="${ICBDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>`,
  },
  {
    id: 25,
    question: "Gdański Uniwersytet Medyczny",
    answer: `<a href="${GUMDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>`,
  },
  {
    id: 26,
    question: "Certyfikat Przejrzysta Firma",
    answer: `<a href="${CPFDoc1}" target="_blank" rel="noreferrer"className={${classes.accordion__item}}>- Referencje</a>`,
  },
];
