import React, { useEffect, useRef, useState } from "react";

import CheckoutSummary from "../components/CheckoutSummary";
import InputField from "../components/InputField";
import { TailSpin } from "react-loader-spinner";
import classes from "./Podsumowanie.module.css";

function Podsumowanie() {
  const [firstAgreementCheckbox, setFirstAgreementCheckbox] = useState(true);
  const [secondAgreementCheckbox, setsecondAgreementCheckbox] = useState(true);
  const [backMessage, setBackMessage] = useState("");
  const [spinnerActive, setspinnerActive] = useState(false);
  const [variable1] = useState(Math.round(Math.random() * 25));
  const [variable2] = useState(Math.round(Math.random() * 25));
  const firstAgreementText =
    "Wyrażam zgodę na przetwarzanie moich danych osobowych w celu przygotowania, złożenia i realizacji ofert i/lub umów handlowych dotyczących produktów do badań i diagnostyki z obszaru „life science” przez Symbios Sp. z o.o. Jednocześnie oświadczam, że zapoznałam/em się z treścią klauzuli informacyjnej. *";
  const secondAgreementText =
    "Wyrażam zgodę na przetwarzanie moich danych osobowych w celu promocji, reklamy produktów i usług Symbios Sp. z o.o. w tym poprzez wykorzystywanie telekomunikacyjnych urządzeń końcowych (kontakt mailowy i telefoniczny) oraz automatycznych systemów wywołujących dla celów marketingu bezpośredniego Symbios zgodnie z Ustawą z dnia 16 lipca 2004 r. Prawo telekomunikacyjne. (opcjonalne)";
  const [cartContent, setCartContent] = useState(JSON.parse(localStorage.getItem("cart")));
  useEffect(() => {
    const timer = setTimeout(() => {
      setCartContent(JSON.parse(localStorage.getItem("cart")));
    }, 1000);
    return () => clearTimeout(timer);
  }, [cartContent]);

  const captchaInput = useRef(0);

  const [queryData, setQueryData] = useState({
    fname: "",
    lname: "",
    insname: "",
    strname: "",
    code: "",
    city: "",
    email: "",
    ordercomment: "",
    firstagreement: true,
    secondagreement: true,
  });

  const inputChangeHandler = (info, indicator) => {
    switch (indicator) {
      case "fname": {
        setQueryData((prev) => ({ ...prev, fname: info }));
        break;
      }
      case "lname": {
        setQueryData((prev) => ({ ...prev, lname: info }));
        break;
      }
      case "insname": {
        setQueryData((prev) => ({ ...prev, insname: info }));
        break;
      }
      case "strname": {
        setQueryData((prev) => ({ ...prev, strname: info }));
        break;
      }
      case "code": {
        setQueryData((prev) => ({ ...prev, code: info }));
        break;
      }
      case "city": {
        setQueryData((prev) => ({ ...prev, city: info }));
        break;
      }
      case "email": {
        setQueryData((prev) => ({ ...prev, email: info }));
        break;
      }
      case "ordercomment": {
        setQueryData((prev) => ({ ...prev, ordercomment: info }));
        break;
      }

      case "secondagreement": {
        setQueryData((prev) => ({ ...prev, secondagreement: info }));
        break;
      }
      default:
        break;
    }
  };

  const sendmail = () => {
    const heading = `<h1>Zapytanie ofertowe</h1>`;
    let items = "";
    JSON.parse(localStorage.getItem("cart")).forEach(
      (element) =>
        (items += `<p><span>${element.title}</span><span> Amount: ${element.amount}</span><span> ${element.sku}</span></p>`)
    );
    const userData = `<div><h1>Dane klienta:</h1><p>${queryData.fname}</p><p>${queryData.lname}</p><p>${
      queryData.insname
    }</p><p>${queryData.strname}</p><p>${queryData.code}</p><p>${queryData.city}</p><p>${queryData.email}</p><p>${
      queryData.ordercomment
    }</p> <h2>Zgoda Rodo:</h2> <p>${
      firstAgreementCheckbox ? `${firstAgreementText} - TAK` : `${firstAgreementText} - NIE`
    }</p> <p>${secondAgreementCheckbox ? `${secondAgreementText} - TAK` : `${secondAgreementText} - NIE`}</p> </div>`;

    if (
      Number(captchaInput.current.value) === variable1 + variable2 &&
      queryData.city.length !== 0 &&
      queryData.code.length !== 0 &&
      queryData.email.length !== 0 &&
      queryData.fname.length !== 0 &&
      queryData.lname.length !== 0 &&
      queryData.insname.length !== 0 &&
      queryData.strname.length !== 0
    ) {
      setspinnerActive(true);
      window.Email.send({
        SecureToken: "ef532d75-22a3-4326-91a9-089b622e0b82",
        From: "Website@symbios.pl",
        To: "Website@symbios.pl",
        Subject: "Zapytanie ofertowe",
        Body: `${heading}${items}${userData}`,
      }).then((message) => {
        setBackMessage(message);
        setspinnerActive(false);
        if (message === "OK") localStorage.setItem("cart", "[]");
      });
    } else {
      setBackMessage("empty fields");
      setspinnerActive(false);
    }
  };

  return (
    <>
      <h1 className={classes.checkout__bigtext}>Zapytanie Ofertowe</h1>
      <hr className={classes.podsumowanie__divider} />

      <div className={classes.container__main}>
        <div className={classes.container__column}>
          <h2 className={classes["checkout__bigtext--h2"]}>Twoje dane</h2>
          <form action="" method="">
            <div className={classes.container__row}>
              <InputField
                text="Imię"
                type="text"
                name="fname"
                required="true"
                autocomplete="on"
                inputChangeHandler={inputChangeHandler}
              />
              <InputField
                text="Nazwisko"
                type="text"
                name="lname"
                required="true"
                autocomplete="on"
                inputChangeHandler={inputChangeHandler}
              />
            </div>
            <div className={classes.container__row}>
              <InputField
                text="Nazwa instytucji"
                type="text"
                name="insname"
                required="true"
                autocomplete="on"
                inputChangeHandler={inputChangeHandler}
              />
              <InputField
                text="Ulica"
                type="text"
                name="strname"
                required="true"
                autocomplete="on"
                inputChangeHandler={inputChangeHandler}
              />
            </div>
            <div className={classes.container__row}>
              <InputField
                text="Kod pocztowy"
                type="text"
                name="code"
                required="true"
                autocomplete="on"
                inputChangeHandler={inputChangeHandler}
              />
              <InputField
                text="Miasto"
                type="text"
                name="city"
                required="true"
                autocomplete="on"
                inputChangeHandler={inputChangeHandler}
              />
            </div>
            <div className={classes.container__row}>
              <InputField
                text="Adres e-mail"
                type="email"
                name="email"
                required="true"
                autocomplete="on"
                inputChangeHandler={inputChangeHandler}
              />
            </div>
            <div className={classes.container__row}>
              <h2 className={classes["checkout__bigtext--h2"]}>Informacje dodatkowe</h2>
            </div>
            <div className={classes["container__row--textarea"]}>
              <label htmlFor="order_comment" className={classes.textarea__label}>
                Uwagi do oferty lub kod promocyjny (opcjonalne)
              </label>
              <textarea
                className={classes.row__texarea}
                name="order_comment"
                id="order_comment"
                placeholder="Uwagi do oferty, np. zmiana NIP instytucji"
                onChange={(e) => inputChangeHandler(e.target.value, "ordercomment")}
              ></textarea>
              <div>
                <p className={classes.whatfor__bparagraph}>
                  Twoje dane osobowe będą użyte do przedstawienia oferty handlowej, ułatwienia korzystania ze strony
                  internetowej oraz innych celów opisanych w naszej polityka prywatności.
                </p>
                <div className={classes.label__box}>
                  <input
                    type="checkbox"
                    id="firstagreement"
                    name="firstagreement"
                    value={firstAgreementCheckbox}
                    checked={firstAgreementCheckbox}
                    onChange={(e) => {
                      setFirstAgreementCheckbox(firstAgreementCheckbox);
                      inputChangeHandler(e.target.checked, e.target.name);
                    }}
                  />
                  <label htmlFor="firstagreement">{firstAgreementText}</label>
                </div>
                <div className={classes.label__box}>
                  <input
                    type="checkbox"
                    id="secondagreement"
                    name="secondagreement"
                    value={secondAgreementCheckbox}
                    checked={secondAgreementCheckbox}
                    onChange={(e) => {
                      setsecondAgreementCheckbox(!secondAgreementCheckbox);
                      inputChangeHandler(e.target.checked, e.target.name);
                    }}
                  />
                  <label htmlFor="secondagreement">{secondAgreementText}</label>
                </div>
              </div>
            </div>
          </form>
          <div className={classes.captcha__styling}>
            <label htmlFor="secondagreement">Ochrona anty-spamowa:</label>
            <div className={classes.captcha__checker}>
              <span>{variable1}</span> + <span>{variable2} = </span>
              <input type="number" ref={captchaInput} className={classes.captcha__input} />
            </div>
          </div>
        </div>
        <div className={classes.container__column}>
          {JSON.parse(localStorage.getItem("cart")).length !== 0 && (
            <>
              <CheckoutSummary />
              <div>
                {spinnerActive && (
                  <TailSpin
                    height="40"
                    width="40"
                    color="#1c3975"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{ marginTop: "2rem" }}
                    wrapperClass=""
                    visible={true}
                  />
                )}

                <button className={classes.send__button} onClick={sendmail}>
                  Wyślij zapytanie
                </button>
              </div>
            </>
          )}
          <div className={classes.message__output}>
            {backMessage === "OK" ? (
              <h3 className={classes["checkout__bigtext--h3"]}>Zapytanie zostało pomyślnie wysłane</h3>
            ) : backMessage === "empty fields" ? (
              <h3 className={classes["checkout__bigtext--h3"]}>Wszystkie pola oznaczone * muszą zostać wypełnione</h3>
            ) : backMessage === "" ? (
              ""
            ) : (
              <h3 className={classes["checkout__bigtext--h3"]}>
                Wystąpił błąd, spróbuj ponownie póżniej lub skontaktuj się z odpowiedzialnym za Twój rynek doradcą
              </h3>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Podsumowanie;
