import React from "react";
import classes from "./Footer.module.css";
function Footer() {
  const currentDate = new Date();
  return (
    <footer className={classes.Footer}>
      <p className={classes.footer__centered}>{currentDate.getFullYear()} © Symbios Sp. z o.o. Wszelkie prawa zastrzeżone.</p>
    </footer>
  );
}

export default Footer;
