import React from "react";
import classes from "./SearchListItem.module.css";
function SearchListItem(props) {
  return (
    <div data-type="search__res" className={classes.search__item}>
      <p data-type="search__res">
        {`${props.items.TITLE} ( ${props.items.SKU} )`}
        <svg
          data-type="search__res"
          version="1.1"
          viewBox="0 0 24 24"
          className={classes.result__svg}
          onClick={() => {
            props.addToCartHandler(props.items.TITLE, props.items.SKU, props.items.MANUFACTURER);
            props.cartCloseHandler();
          }}
        >
          <g id="info" />
          <g id="icons">
            <path
              data-type="search__res"
              d="M12,1C5.9,1,1,5.9,1,12s4.9,11,11,11s11-4.9,11-11S18.1,1,12,1z M17,14h-3v3c0,1.1-0.9,2-2,2s-2-0.9-2-2v-3H7   c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2h3V7c0-1.1,0.9-2,2-2s2,0.9,2,2v3h3c1.1,0,2,0.9,2,2C19,13.1,18.1,14,17,14z"
              id="add"
            />
          </g>
        </svg>
      </p>
    </div>
  );
}

export default SearchListItem;
