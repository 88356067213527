import React from "react";
import classes from "./Kategorie.module.css";
import { Link, useParams } from "react-router-dom";
import kategorieData from "./KategorieData";
import SingleRow from "../components/Categories/SingleRow";

function Kategorie() {
  const params = useParams();
  const result = kategorieData.filter((obj) => {
    return obj.group === params.group;
  });

  const listItems = result[0].companies.map((item) => (
    <div key={item.id}>
      <SingleRow href={item.href} company={item.company} text={item.text} logo={item.logo} alt={item.alt} />
    </div>
  ));

  return (
    <div>
      {/* <Instructions /> */}
      <div className={classes.first__decoration}>
        <div className={classes.inside__divider}></div>
        <p>Znajdź numer katalogowy</p>
        <h2>TEN ASORTYMENT ZNAJDZIESZ U PONIŻSZYCH PRODUCENTÓW</h2>
      </div>
      <div className={classes.main__wrapper}>
        <h1 className={classes.h1__decorated}>JAK ZŁOŻYĆ ZAPYTANIE O OFERTĘ</h1>
        <div className={classes.button__wrapper}>
          <Link to="/Instrukcja" className={classes.manufacturers__button}>
            SPRAWDŹ
          </Link>
        </div>
        <div className={classes.main__width}>{listItems}</div>
      </div>
    </div>
  );
}

export default Kategorie;
