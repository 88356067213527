import { FaBuilding, FaMailBulk, FaPhoneAlt } from "react-icons/fa";

import Map from "../components/Map";
import React from "react";
import classes from "./Kontakt.module.css";

function Kontakt() {
  return (
    <section className={classes.main__wrapper}>
      <h1 className={classes.main__heading}>
        Zachęcamy do kontaktu z naszą firmą. Pozostajemy do Państwa dyspozycji telefonicznie, mailowo.
      </h1>
      <hr className={classes.contact__divider} />
      <div className={classes.flex__fix}>
        <div className={classes.contact__box}>
          <FaBuilding width='50px'/>
          <div className={classes["contact__box--tac"]}>
            <h2 className={classes.heading__secondary}>Symbios Sp. z o.o.</h2>
            <p>
              Ul. Jaśkowa Dolina 68, 80-286 Gdańsk <br />
              NIP: 9570703157 KRS: 0000142093 REGON: 191540030
            </p>
          </div>
        </div>
        <div className={classes.contact__box}>
          <FaPhoneAlt />
          <div className={classes["contact__box--tac"]}>
            <h2 className={classes.heading__secondary}>Zadzwoń do nas</h2>
            <p>+58 692 80 83</p>
          </div>
        </div>

        <div className={classes.contact__box}>
          <FaMailBulk />
          <div className={classes["contact__box--tac"]}>
            <h2 className={classes.heading__secondary}>Napisz nam wiadomość</h2>
            <p>info@symbios.pl</p>
          </div>
        </div>
      </div>
      {/* <div className={classes.flex__fix}>
        <h3 className={classes.heading__secondary}>Podział na województwa:</h3>
      </div> */}
      {/* <div className={classes.map__wrapper}>
        <Map />
      </div> */}
    </section>
  );
}

export default Kontakt;
