import React from "react";
import classes from "./DolaczDoNas.module.css";

function DolaczDoNas() {
    return (
        <div className={classes.section__wrapper}>
            <h1 className={classes.heading__main}>Doradca Klienta Specjalista ds. Sprzedaży w branży
                biotechnologicznej</h1>
            <hr className={classes.joinus__divider}/>
            <h2 className={classes.heading__secondary}>Wymagania:</h2>
            <ul className={classes.unordered__list}>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>Wyższe
                        studia w kierunku biotechnologia, biologia i pokrewne
                    </div>
                </li>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>Znajomość
                        branżowego języka angielskiego w mowie i w piśmie na poziomie dobrym
                    </div>
                </li>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>Umiejętność
                        pracy w zespole
                    </div>
                </li>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>Komunikatywność
                        i wysoka kultura osobista
                    </div>
                </li>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>Dobra
                        organizacja pracy
                    </div>
                </li>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>Samodzielność
                    </div>
                </li>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>Znajomość
                        pakietu Office w stopniu dobrym
                    </div>
                </li>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>Prawo
                        jazdy kat. B
                    </div>
                </li>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>Doświadczenie
                        w sprzedaży w branży biotechnologicznej będzie dodatkowym atutem
                    </div>
                </li>
            </ul>
            <h2 className={classes.heading__secondary}>Opis obowiązków:</h2>
            <ul className={classes.unordered__list}>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>Nawiązywanie,
                        utrzymywanie i rozwijanie kontaktów z klientami oraz samodzielna praca z grupą klientów
                    </div>
                </li>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>
                        Doradztwo - prowadzenie wyjaśnień w zakresie merytorycznym i technicznym
                    </div>
                </li>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>
                        Prezentacje i szkolenia dla klientów w zakresie oferowanych produktów
                    </div>
                </li>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>
                        Przygotowywanie i składanie ofert handlowych oraz sprzedaż produktów na rynku life science
                    </div>
                </li>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>Realizacja
                        celów sprzedażowych
                    </div>
                </li>
            </ul>
            <h2 className={classes.heading__secondary}>Firma oferuje:</h2>
            <ul className={classes.unordered__list}>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>
                        Pakiety prywatnej opieki medycznej
                    </div>
                </li>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>
                        Samochód służbowy do użytku prywatnego dla pracowników terenowych
                    </div>
                </li>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>Stabilne
                        warunki zatrudnienia
                    </div>
                </li>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>Branżowe
                        szkolenia krajowe i zagraniczne
                    </div>
                </li>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>
                        Pracę w młodym, doskonale przygotowanym merytorycznie zespole (100% zespołu to absolwenci
                        kierunku Biotechnologia)
                    </div>
                </li>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>Pracę
                        w ciekawej i ciągle rozwijającej się firmie
                    </div>
                </li>
                <li>
                    <div className={classes.li__wrapper}>
                        <span className={classes.list__number}><span className={classes.number__marker}></span></span>
                        Dostęp do informacji o najnowszych rozwiązaniach naukowo-technologicznych w branży life science
                    </div>
                </li>


            </ul>
            <hr className={classes.joinus__divider}/>
            <h3 className={classes.heading__tertiary}>Osoby zainteresowane rekrutacją prosimy o przesłanie CV na
                adres:</h3>
            <p className={classes.email}>praca@symbios.pl</p>
            <p className={classes.request}>Prosimy o zamieszczenie na dokumentach klauzuli:</p>
            <p className={classes.request__text}>
                „Wyrażam zgodę na przetwarzanie moich danych osobowych przez firmę Symbios sp. z o.o. dla potrzeb
                niezbędnych do realizacji procesu rekrutacji (zgodnie
                z ustawą z dnia 10 maja 2018 roku o ochronie danych osobowych (Dz. Ustaw z 2018, poz. 1000) oraz zgodnie
                z Rozporządzeniem Parlamentu Europejskiego i
                Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
                przetwarzaniem danych osobowych i w sprawie swobodnego
                przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO)).
            </p>
        </div>
    );
}

export default DolaczDoNas;
