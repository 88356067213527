import React from "react";
import useOpenController from "./useOpenController";
import classes from "./Accordion.module.css";
export const Accordion = ({ section }) => {
  const { isOpen, toggle } = useOpenController(false);

  return (
    <div className={classes["accordion-container"]}>
      <ExpendableColumn
        question={section.question}
        isOpen={isOpen}
        toggle={toggle}
      />
      {isOpen && <TextSection text={section.answer} />}
      <div className={classes.underline}></div>
    </div>
  );
};

export const ExpendableColumn = ({ question, isOpen, toggle }) => {
  return (
    <div className={classes["column-container"]} onClick={toggle}>
      <div className={classes["column-text"]}>{question}</div>
      <button className={classes["expendable-button"]}>
        <span className={classes["material-symbols-outlined"]}>+</span>
      </button>
    </div>
  );
};

export const TextSection = ({ text }) => {
  return (
    <div className={classes["text-container"]}>
      <div dangerouslySetInnerHTML={{ __html: text }}></div>
    </div>
  );
};
