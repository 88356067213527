import React from "react";
import classes from "./WsparcieSection3.module.css";
import celonLogo from "./assets/images/OutsideCompanies/celon.png";
import ibbLogo from "./assets/images/OutsideCompanies/IBBLogo.svg";
import ugLogo from "./assets/images/OutsideCompanies/ugLogo.png";
import umLogo from "./assets/images/OutsideCompanies/umLogo.png";
import umlubLogo from "./assets/images/OutsideCompanies/umlubLogo.png";
import uwLogo from "./assets/images/OutsideCompanies/uwLogo.png";
import nenckiLogo from "./assets/images/OutsideCompanies/nenckiLogo.svg";
import gumedLogo from "./assets/images/OutsideCompanies/gumedLogo.png";
import SingleCompanyLogo from "./SingleCompanyLogo/SingleCompanyLogo";
function WsparcieSection3() {
  const companiesFirstRow = [
    {
      id: 1,
      name: "Celon",
      www: "https://celonpharma.com/",
      image: celonLogo,
    },
    {
      id: 2,
      name: "Instytut Biochemii i Biofizyki",
      www: "https://ibb.edu.pl/",
      image: ibbLogo,
    },
    {
      id: 3,
      name: "Uniwersytet Gdański",
      www: "https://ug.edu.pl/",
      image: ugLogo,
    },
    {
      id: 4,
      name: "Uniwersytet Medyczny w Łodzi",
      www: "https://umed.pl/",
      image: umLogo,
    },
  ];
  const companiesSecondRow = [
    {
      id: 5,
      name: "Uniwersytet Medyczny w Lublinie",
      www: "https://www.umlub.pl/",
      image: umlubLogo,
    },
    {
      id: 6,
      name: "Uniwersytet Warszawski",
      www: "https://www.uw.edu.pl/",
      image: uwLogo,
    },
    {
      id: 7,
      name: "Instytut Biologii Doświadczalnej im. M. Nenckiego PAN",
      www: "https://nencki.edu.pl/pl/",
      image: nenckiLogo,
    },
    {
      id: 8,
      name: "Gdański Uniwersytet Medyczny",
      www: "https://gumed.edu.pl/",
      image: gumedLogo,
    },
  ];

  return (
    <section className={classes.section__wrapper}>
      <h1>Nasi klienci</h1>
      <hr />
      <div className={classes.logos__wrapper}>
        <div className={classes.logos__container}>
          {companiesFirstRow.map((item) => (
            <SingleCompanyLogo
              name={item.name}
              // www={item.www}
              image={item.image}
              key={item.id}
            />
          ))}
        </div>
        <div className={classes.logos__divider}></div>
        <div className={classes.logos__container}>
          {companiesSecondRow.map((item) => (
            <SingleCompanyLogo
              name={item.name}
              // www={item.www}
              image={item.image}
              key={item.id}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default WsparcieSection3;
